<template>
    <div class="courseDetail-wrapper" v-if="Object.keys(courseDetail).length !== 0">
        <div class="back-wrapper">
            <el-button type="primary" @click="backTo" style="margin-right: 72px">返回</el-button>
        </div>
        <el-scrollbar class="courseDetail-content" :native="false">
            <div class="courseDetail-banner">
                <div class="bg-circle" style="top: 20px"></div>
                <div class="bg-circle" style="bottom: 62px"></div>
                <div class="bg-colorBlock"></div>
                <div class="bg-people"></div>
                <div class="banner-content">
                    <div class="course-name text-overflow-2">{{courseDetail.course_name}}</div>
                    <div class="toCourse-btn">去上课 >></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.contnet_description">
                <div class="item-caption">
                    <span class="text">课程介绍</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="introduce-wrapper" v-html="courseDetail.contnet_description"></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.jx_standard">
                <div class="item-caption">
                    <span class="text">教学标准</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="content-table yellow-table" v-html="courseDetail.jx_standard"></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.resource_type">
                <div class="item-caption">
                    <span class="text">资源类型</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="content-table blue-table" v-html="courseDetail.resource_type"></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.wk_introductory">
                <div class="item-caption">
                    <span class="text">微课简介</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="content-table purple-table" v-html="courseDetail.wk_introductory"></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.sx_introductory">
                <div class="item-caption">
                    <span class="text">实训简介</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="content-table pink-table" v-html="courseDetail.sx_introductory"></div>
                </div>
            </div>
            <div class="courseDetail-item" v-if="courseDetail.correlation_course_data.length > 0">
                <div class="item-caption">
                    <span class="text">相关课程</span>
                    <div class="item-caption-bg1"></div>
                    <div class="item-caption-bg2"></div>
                </div>
                <div class="item-content">
                    <div class="relatedCourse-wrapper">
                        <div class="relatedCourse-item" v-for="(courseItem, courseIndex) in courseDetail.correlation_course_data" :key="`courseList_${courseIndex}`">
                            <div class="course-cover">
                                <img :src="courseItem.course_cover.src" alt="">
                            </div>
                            <div class="course-name text-overflow" :title="courseItem.course_name">{{courseItem.course_name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                course_id: Number(this.$route.query.course_id) || '',
                courseDetail: {}
            }
        },
        mounted() {
            this.getCourseDetail()
        },
        methods: {
            // 返回
            backTo() {
                this.$router.go(-1)
            },
            // 获取课程详情页
            getCourseDetail() {
                let role = Number(localStorage.getItem('role'))
                let getMethod = {
                    2: this.$http,
                    3: this.$thttp,
                    4: this.$shttp
                }
                getMethod[role].axiosGetBy(this.$api.contents_showCourseContent, {course_id: this.course_id}, (res) => {
                    if (res.code === 200) {
                        this.courseDetail = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep table {
        tr {
            * {
                margin: 0;
            }
        }
    }
    .courseDetail-wrapper {
        width: 100%;
        height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        .back-wrapper {
            padding: 20px 0 15px;
            text-align: right;
            background: #fff;
        }
        .courseDetail-content {
            flex: 1;
            height: 1%;
            background: #9F9FFF;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    min-width: 1400px;
                }
            }
            .courseDetail-banner {
                height: 450px;
                background: #6F6DFC;
                position: relative;
                .bg-circle {
                    position: absolute;
                    left: 10px;
                    right: 0;
                    height: 96px;
                    background: url("../../assets/images/courseDetail/circle.png") repeat;
                }
                .bg-colorBlock {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 30px;
                    background: url("../../assets/images/courseDetail/colorBlock.png") repeat;
                }
                .bg-people {
                    position: absolute;
                    top: 0;
                    right: 150px;
                    width: 607px;
                    height: 448px;
                    background: url("../../assets/images/courseDetail/people.png") no-repeat;
                }
                .banner-content {
                    width: 1400px;
                    margin: 0 auto;
                    position: relative;
                    .course-name {
                        width: calc(100% - 607px);
                        padding-top: 110px;
                        padding-left: 10px;
                        font-size: 60px;
                        color: #fff;
                        font-weight: bold;
                        line-height: 1.5;
                        z-index: 1;
                        text-shadow: -5px 5px 0 #2E208F, 5px 5px 0 #2E208F, 5px -5px 0 #2E208F, -5px -5px 0 #2E208F;
                        /*-webkit-text-stroke: 3px #2E208F;*/
                    }
                    .toCourse-btn {
                        margin-top: 50px;
                        width: 262px;
                        height: 62px;
                        background: #B7FDB7;
                        border: 6px solid #2E208F;
                        padding-left: 54px;
                        line-height: 50px;
                        box-sizing: border-box;
                        font-size: 24px;
                        color: #2E208F;
                        font-weight: bold;
                        /*cursor: pointer;*/
                    }
                }
            }
            .courseDetail-item {
                &:last-child {
                    padding-bottom: 120px;
                }
                .item-caption {
                    width: 368px;
                    height: 80px;
                    line-height: 80px;
                    margin: 80px auto 0;
                    position: relative;
                    .text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 3;
                        font-size: 36px;
                        color: #fff;
                        font-weight: bold;
                        //text-shadow: -3px 3px 0 #2E208F, 3px 3px 0 #2E208F, 3px -3px 0 #2E208F, -3px -3px 0 #2E208F;
                        text-shadow: -2px 2px 0 #2E208F, 2px 2px 0 #2E208F, 2px -2px 0 #2E208F, -2px -2px 0 #2E208F;
                        /*-webkit-text-stroke: 1px #2E208F;*/
                    }
                    .item-caption-bg1 {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: 368px;
                        height: 80px;
                        box-sizing: border-box;
                        border-radius: 40px;
                        background: #F1D246;
                        border: 4px solid #2E208F;
                    }
                    .item-caption-bg2 {
                        position: absolute;
                        z-index: 1;
                        top: 12px;
                        left: 0;
                        width: 368px;
                        height: 80px;
                        box-sizing: border-box;
                        border-radius: 40px;
                        background: #E47192;
                        border: 4px solid #2E208F;
                    }
                }
                .item-content {
                    margin: 56px 68px 0;
                    position: relative;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: -15px;
                        width: 30px;
                        height: 30px;
                        background: #F1D246;
                        border-radius: 50%;
                        border: 4px solid #2E208F;
                        box-sizing: border-box;
                    }
                    &:before {
                        left: calc(50% - 214px);
                    }
                    &:after {
                        right: calc(50% - 214px);
                    }
                    .introduce-wrapper {
                        padding: 60px;
                        font-size: 18px;
                        text-indent: 2em;
                        background: #FFFDE8;
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        ::v-deep p {
                            margin: 0;
                        }
                    }
                    ::v-deep .content-table {
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        table {
                            border-collapse: collapse!important;
                            width: 100%!important;
                            border-color: transparent;
                            border-bottom: none;
                            margin-top: -4px;
                            tr {
                                height: auto!important;
                                th, td {
                                    border-right: 4px solid #fff;
                                    border-top: 4px solid #fff;
                                    &:first-child {
                                        border-left: 0;
                                        /*text-align: center;*/
                                    }
                                    &:last-child {
                                        border-right: 0;
                                        /*text-align: center;*/
                                    }
                                }
                                th {
                                    text-align: left;
                                    padding: 20px;
                                    height: 20px!important;
                                    line-height: 20px;
                                    box-sizing: border-box;
                                    font-size: 18px;
                                    color: #2A1E05;
                                    white-space: nowrap;
                                }
                                td {
                                    height: auto!important;
                                    text-align: left;
                                    padding: 20px;
                                    font-size: 18px;
                                    color: #444;
                                }
                            }
                        }
                        &.yellow-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #fffde8;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #f0edc6;
                                        }
                                    }
                                    th {
                                        background: #E3DD9B;
                                    }
                                }
                            }
                        }
                        &.blue-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #e7f7ff;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #D3F1FD;
                                        }
                                    }
                                    th {
                                        background: #B7E7FB;
                                    }
                                }
                            }
                        }
                        &.purple-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #E0E0F9;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #F2F2FC;
                                        }
                                    }
                                    th {
                                        background: #BABAFC;
                                    }
                                }
                            }
                        }
                        &.pink-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #FEE9EF;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #FAF2FE;
                                        }
                                    }
                                    th {
                                        background: #FABBCD;
                                    }
                                }
                            }
                        }
                    }
                    .relatedCourse-wrapper {
                        padding: 0 100px 20px 50px;
                        background: #FFFDE8;
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        display: flex;
                        flex-wrap: wrap;
                        .relatedCourse-item {
                            margin-top: 40px;
                            margin-left: 50px;
                            width: calc(100% / 7 - 50px);
                            .course-cover {
                                width: 100%;
                                height: 0;
                                padding-bottom: 56.179775%;
                                overflow: hidden;
                                position: relative;
                                background: rgba(159, 159, 255, .2);
                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .course-name {
                                width: 100%;
                                margin: 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>